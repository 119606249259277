import { Injectable } from '@angular/core';
import { MenuItem, MenuService } from './menu.service';
import { Permission } from '../interfaces/permission';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  public permissions: Permission[] = [];

  constructor(private menu: MenuService) {
    this.getAllPermission(this.menu.mainMenu);
  }

  private getAllPermission(menulevel: MenuItem[]) {
    menulevel.forEach(x => {
      if(x.permission != null) {
        this.permissions.push({ key: x.permission, permission: false });
      }

      if(x.items?.length > 0) {
        this.getAllPermission(x.items);
      }
    });
  }
}
