import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Sha256 } from '@aws-crypto/sha256-browser';

import { DomainService } from '../services/domain.service';
import { AuthenService } from '../services/authen.service';
import { LogUserService } from './log-user.service';

import { User, Role, userAPIResponse } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private serviceEndpoint: string = this.host.domain + '/user';

  private logErrorMessage: userAPIResponse = {
    status: 0,
    result: [],
    message: 'ระบบขัดข้องไม่สามารถดำเนินการได้'
  }

  constructor(
    private host: DomainService,
    private http: HttpClient,
    private auth: AuthenService,
    private logs: LogUserService
  ) { }

  private getLogMessage(status: number): string {
    let logMessage = '';

    switch(status) {
      case 0:
        logMessage = 'failed';
        break;
      case 1:
        logMessage = 'success';
        break;
    }

    return logMessage;
  }

  public async ping(): Promise<string> {
    let res = await this.http.get(this.serviceEndpoint + '/', { responseType: 'text' }).toPromise();

    return res;
  }

  public async authenUser(userid: string, passcode: string): Promise<userAPIResponse> {
    const hash = new Sha256();
    hash.update(passcode, 'utf8');
    const result = await hash.digest();
    const hashArray = Array.from(new Uint8Array(result));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    let res: userAPIResponse;
    try {
      res = await this.http.post<userAPIResponse>(
        this.serviceEndpoint + '/login',
        { userid: userid, passcode: hashHex }
      ).toPromise();
    } catch(err) {
      res = this.logErrorMessage;
      console.log('UserAPI', err);
    }

    return res;
  }

  public async getAllUsers(): Promise<User[]> {
    let res: userAPIResponse;
    try {
      res = await this.http.get<userAPIResponse>(this.serviceEndpoint + '/all').toPromise();
    } catch(err) {
      res = this.logErrorMessage;
      console.log('UserAPI', err);
    }

    return <User[]>res.result;
  }

  public async getUser(id: number): Promise<User> {
    let user: User;
    try {
      user = <User>await (await this.http.get<userAPIResponse>(this.serviceEndpoint + '/id/' + id).toPromise()).result.pop();
    } catch(err) {
      console.log('UserAPI', err);
    }

    return user;
  }
  
  public async submitUser(info): Promise<userAPIResponse> {
    const hash = new Sha256();
    hash.update(info.passcode, 'utf8');
    const result = await hash.digest();
    const hashArray = Array.from(new Uint8Array(result));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    let userInfo = {
      firstname: info.firstname,
      lastname: info.lastname,
      userid: info.userid,
      roleid: info.roleid,
      passcode: hashHex,
      jobposition: info.jobposition,
      department: info.department,
      email: info.email,
      telno: info.telno,
      def_notification: info.def_notification
    }

    let res: userAPIResponse;
    try {
      res = await this.http.post<userAPIResponse>(this.serviceEndpoint + '/', userInfo).toPromise();

      let logMessage = this.getLogMessage(res.status);

      let log = {
        user_id: this.auth.id,
        incident: 'add user',
        logdetail: `{ userid: "${info.userid}", firstname: "${info.firstname}", lastname: "${info.lastname}", roleparam: "${info.roleparam}", jobposition: "${info.jobposition}", department: "${info.department}", email: "${info.email}", telno: "${info.telno}", def_notification: "${info.def_notification}", result: "${logMessage}" }`
      }
      await this.logs.createUserLogs(log);
    } catch(err) {
      res = this.logErrorMessage;
      console.log('UserAPI', err);
    }

    return res;
  }

  public async updateUser(info, currentUser: User): Promise<userAPIResponse> {
    let userInfo = {
      firstname: info.firstname,
      lastname: info.lastname,
      userid: info.userid,
      roleparam: info.roleparam,
      locked: info.locked ? '1' : '0',
      jobposition: info.jobposition,
      department: info.department,
      email: info.email,
      telno: info.telno,
      def_notification: info.def_notification
    }

    let res: userAPIResponse;
    try {
      res = await this.http.post<userAPIResponse>(this.serviceEndpoint + '/id/' + info.id, userInfo).toPromise();

      let logMessage = this.getLogMessage(res.status);

      let log = {
        user_id: this.auth.id,
        incident: 'update user',
        logdetail: this.checkDetailsChange(info, currentUser, logMessage)
      }
      await this.logs.createUserLogs(log);
    } catch(err) {
      res = this.logErrorMessage;
      console.log('UserAPI', err);
    }

    return res;
  }

  public checkDetailsChange(info, currentUser: User, logMessage: string): string {
    let logdetail = '{ id: ' + currentUser.id + ', userid: "' + currentUser.userid + '"';

    if(info.firstname != currentUser.firstname) {
      logdetail += ', firstname: "' + info.firstname + '"';
    }

    if(info.lastname != currentUser.lastname) {
      logdetail += ', lastname: "' + info.lastname + '"';
    }

    if(info.roleparam != currentUser.roleparam) {
      logdetail += ', roleparam: "' + info.roleparam + '"';
    }

    if(info.locked != currentUser.locked) {
      logdetail += ', locked: ' + (info.locked ? '1' : '0');
    }

    if(info.jobposition != currentUser.jobposition) {
      logdetail += ', jobposition: "' + info.jobposition + '"';
    }

    if(info.department != currentUser.department) {
      logdetail += ', department: "' + info.department + '"';
    }

    if(info.email != currentUser.email) {
      logdetail += ', email: "' + info.email + '"';
    }

    if(info.telno != currentUser.telno) {
      logdetail += ', telno: "' + info.telno + '"';
    }

    if(info.def_notification != currentUser.def_notification) {
      logdetail += ', def_notification: "' + info.def_notification + '"';
    }

    logdetail += `, result: "${logMessage}" }`;

    return logdetail;
  }

  public async changePasscode(info): Promise<userAPIResponse> {
    const hashOld = new Sha256();
    hashOld.update(info.oldpasscode, 'utf8');
    const resultOld = await hashOld.digest();
    const hashArrayOld = Array.from(new Uint8Array(resultOld));
    const hashHexOld = hashArrayOld.map(b => b.toString(16).padStart(2, '0')).join('');

    const hashNew = new Sha256();
    hashNew.update(info.newpasscode, 'utf8');
    const resultNew = await hashNew.digest();
    const hashArrayNew = Array.from(new Uint8Array(resultNew));
    const hashHexNew = hashArrayNew.map(b => b.toString(16).padStart(2, '0')).join('');

    let userInfo = {
      id: info.id,
      oldpass: hashHexOld,
      newpass: hashHexNew
    }

    let res: userAPIResponse;
    try {
      res = await this.http.post<userAPIResponse>(this.serviceEndpoint + '/changepass', userInfo).toPromise();

      let logMessage = this.getLogMessage(res.status);

      let log = {
        user_id: this.auth.id,
        incident: 'change passcode',
        logdetail: `{ id: ${info.id}, result: "${logMessage}" }`
      }
      await this.logs.createUserLogs(log);
    } catch(err) {
      res = this.logErrorMessage;
      console.log('UserAPI', err);
    }

    return res;
  }

  public async deleteUser(id: number, userid: string): Promise<userAPIResponse> {
    let res: userAPIResponse;
    try {
      res = await this.http.delete<userAPIResponse>(this.serviceEndpoint + '/id/' + id).toPromise();

      let logMessage = this.getLogMessage(res.status);

      let log = {
        user_id: this.auth.id,
        incident: 'delete user',
        logdetail: `{ id: ${id}, userid: "${userid}", result: "${logMessage}" }`
      }
      await this.logs.createUserLogs(log);
    } catch(err) {
      res = this.logErrorMessage;
      console.log('UserAPI', err);
    }

    return res;
  }

  public async getAllRoles(): Promise<Role[]> {
    let res: userAPIResponse;
    try {
      res = await this.http.get<userAPIResponse>(this.serviceEndpoint + '/role/all').toPromise();
    } catch(err) {
      res = this.logErrorMessage;
      console.log('UserAPI', err);
    }

    return <Role[]>res.result;
  }
}
