import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'vehicles',
    loadChildren: () => import('./vehicles/vehicles.module').then( m => m.VehiclesPageModule)
  },
  {
    path: 'vehicle-status',
    loadChildren: () => import('./vehicle-status/vehicle-status.module').then( m => m.VehicleStatusPageModule)
  },
  {
    path: 'locations',
    loadChildren: () => import('./locations/locations.module').then( m => m.LocationsPageModule)
  },
  {
    path: 'package-types',
    loadChildren: () => import('./package-types/package-types.module').then( m => m.PackageTypesPageModule)
  },
  {
    path: 'system-variables',
    loadChildren: () => import('./system-variables/system-variables.module').then( m => m.SystemVariablesPageModule)
  },
  {
    path: 'log-user',
    loadChildren: () => import('./log-user/log-user.module').then( m => m.LogUserPageModule)
  },
  {
    path: 'log-vehicle',
    loadChildren: () => import('./log-vehicle/log-vehicle.module').then( m => m.LogVehiclePageModule)
  },
  {
    path: 'scan-load',
    loadChildren: () => import('./scan-load/scan-load.module').then( m => m.ScanLoadPageModule)
  },
  {
    path: 'scan-unload',
    loadChildren: () => import('./scan-unload/scan-unload.module').then( m => m.ScanUnloadPageModule)
  },
  {
    path: 'rooms',
    loadChildren: () => import('./rooms/rooms.module').then( m => m.RoomsPageModule)
  },
  {
    path: 'time-windows',
    loadChildren: () => import('./time-windows/time-windows.module').then( m => m.TimeWindowsPageModule)
  },
  {
    path: 'packages',
    loadChildren: () => import('./packages/packages.module').then( m => m.PackagesPageModule)
  },
  {
    path: 'scan-loading/:tripuuid',
    loadChildren: () => import('./scan-loading/scan-loading.module').then( m => m.ScanLoadingPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'trips',
    loadChildren: () => import('./trips/trips.module').then( m => m.TripsPageModule)
  },
  {
    path: 'scan-unloading/:tripuuid',
    loadChildren: () => import('./scan-unloading/scan-unloading.module').then( m => m.ScanUnloadingPageModule)
  },
  {
    path: 'issue-search',
    loadChildren: () => import('./issue-search/issue-search.module').then( m => m.IssueSearchPageModule)
  },
  {
    path: 'package-manage',
    loadChildren: () => import('./package-manage/package-manage.module').then( m => m.PackageManagePageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'log-notification',
    loadChildren: () => import('./log-notification/log-notification.module').then( m => m.LogNotificationPageModule)
  },
  {
    path: 'notif-types',
    loadChildren: () => import('./notif-types/notif-types.module').then( m => m.NotifTypesPageModule)
  },
  {
    path: 'report-parcel',
    loadChildren: () => import('./report-parcel/report-parcel.module').then( m => m.ReportParcelPageModule)
  },
  {
    path: 'report-issue',
    loadChildren: () => import('./report-issue/report-issue.module').then( m => m.ReportIssuePageModule)
  },
  {
    path: 'report-trip',
    loadChildren: () => import('./report-trip/report-trip.module').then( m => m.ReportTripPageModule)
  },
  {
    path: 'log-task',
    loadChildren: () => import('./log-task/log-task.module').then( m => m.LogTaskPageModule)
  },
  {
    path: 'issue-document',
    loadChildren: () => import('./issue-document/issue-document.module').then( m => m.IssueDocumentPageModule)
  },
  {
    path: 'report-room',
    loadChildren: () => import('./report-room/report-room.module').then( m => m.ReportRoomPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
