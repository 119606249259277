import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DomainService } from '../services/domain.service';

import { UserLog, RecordCount, userlogAPIResponse } from '../interfaces/userlog';

@Injectable({
  providedIn: 'root'
})
export class LogUserService {
  private serviceEndpoint: string = this.host.domain + '/log_user';

  constructor(private host: DomainService, private http: HttpClient) { }

  public async ping(): Promise<string> {
    let res = await this.http.get(this.serviceEndpoint + '/', { responseType: 'text' }).toPromise();

    return res;
  }

  public async createUserLogs(info): Promise<boolean> {
    let loginfo = {
      user_id: info.user_id,
      incident: info.incident,
      logdetail: info.logdetail
    }

    let res = await this.http.post<userlogAPIResponse>(this.serviceEndpoint + '/', loginfo).toPromise();

    return res.status == 1;
  }

  public async getUserLogs(
    limit: number = 10,
    page: number = 1,
    sort: string = 'desc',
    datefrom: string = null,
    dateto: string = formatDate(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
    userid: string = null,
    incident: string = null,
    logdetail: string = null
  ): Promise<UserLog[]> {
    let filterInfo = {
      limit: limit,
      page: page,
      sort: sort,
      datefrom: datefrom,
      dateto: dateto,
      userid: userid,
      incident: incident,
      logdetail: logdetail
    }

    let res = await this.http.post<userlogAPIResponse>(this.serviceEndpoint + '/filter', filterInfo).toPromise();

    return <UserLog[]>res.result;
  }

  public async getRecordCount(
    datefrom: string = null,
    dateto: string = formatDate(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
    userid: string = null,
    incident: string = null,
    logdetail: string = null
  ): Promise<RecordCount[]> {
    let filterInfo = {
      datefrom: datefrom,
      dateto: dateto,
      userid: userid,
      incident: incident,
      logdetail: logdetail
    }

    let res = await this.http.post<userlogAPIResponse>(this.serviceEndpoint + '/count/record', filterInfo).toPromise();

    return <RecordCount[]>res.result;
  }
  
}
