import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  version: string = 'v1.0.20230902';

  constructor() { }
}
