import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DomainService } from '../services/domain.service';

@Injectable({
  providedIn: 'root'
})
export class AboutService {
  private serviceEndpoint: string = this.host.domain

  constructor(
    private host: DomainService,
    private http: HttpClient
  ) { }

  public async ping(): Promise<string> {
    let res = await this.http.get(this.serviceEndpoint, { responseType: 'text' }).toPromise();

    return res;
  }

}
