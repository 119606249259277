import { Injectable } from '@angular/core';

export class MenuItem {
  label: string;
  icon: string;
  permission?: string;
  routerLink?: string;
  items?: MenuItem[];
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  showMenu: boolean = true;

  public mainMenu: MenuItem[] = [
    {
      label: 'แดชบอร์ด',
      icon: 'globe-outline',
      routerLink: '/dashboard',
      permission: 'dashboard'
    },
    {
      label: 'การขนส่ง',
      icon: 'git-pull-request-outline',
      permission: 'delivery',
      items: [
        {
          label: 'พัสดุ',
          icon: 'cube-outline',
          routerLink: '/packages',
          permission: 'packages'
        },
        {
          label: 'จัดการพัสดุ',
          icon: 'albums-outline',
          routerLink: '/package-manage',
          permission: 'package-manage'
        },
        {
          label: 'ใบเบิก',
          icon: 'document-text-outline',
          routerLink: '/issue-document',
          permission: 'issue-document'
        },
        {
          label: 'ค้นหาใบเบิก',
          icon: 'search-circle-outline',
          routerLink: '/issue-search',
          permission: 'issue-search'
        },
        {
          label: 'เที่ยวรถ',
          icon: 'trail-sign-outline',
          routerLink: '/trips',
          permission: 'trips'
        }
      ]
    },
    {
      label: 'สแกนพัสดุ',
      icon: 'qr-code-outline',
      permission: 'scan',
      items: [
        {
          label: 'บรรทุกพัสดุ',
          icon: 'arrow-up-circle-outline',
          routerLink: '/scan-load',
          permission: 'scan-load'
        },
        {
          label: 'รับพัสดุ',
          icon: 'arrow-down-circle-outline',
          routerLink: '/scan-unload',
          permission: 'scan-unload'
        }
      ]
    },
    {
      label: 'แจ้งเตือน',
      icon: 'notifications-outline',
      routerLink: '/notifications',
      permission: 'notification'
    },
    {
      label: 'รายงาน',
      icon: 'receipt-outline',
      permission: 'report',
      items: [
        {
          label: 'รายงานการจัดส่งตามห้องยา',
          icon: 'git-network-outline',
          routerLink: '/report-room',
          permission: 'report-room'
        },
        {
          label: 'รายงานเที่ยวจัดส่ง',
          icon: 'unlink-outline',
          routerLink: '/report-trip',
          permission: 'report-trip'
        },
        {
          label: 'รายงานการจัดส่งตามใบเบิก',
          icon: 'file-tray-full-outline',
          routerLink: '/report-issue',
          permission: 'report-issue'
        },
        {
          label: 'รายงานสรุปจำนวนพัสดุ',
          icon: 'browsers-outline',
          routerLink: '/report-parcel',
          permission: 'report-parcel'
        }
      ]
    },
    {
      label: 'การตั้งค่า',
      icon: 'settings-outline',
      permission: 'config',
      items: [
        {
          label: 'ห้องยา',
          icon: 'flask-outline',
          routerLink: '/rooms',
          permission: 'config-rooms'
        },
        {
          label: 'สถานที่',
          icon: 'business-outline',
          routerLink: '/locations',
          permission: 'config-locations'
        },
        {
          label: 'ยานพาหนะ',
          icon: 'car-outline',
          routerLink: '/vehicles',
          permission: 'config-vehicles'
        },
        {
          label: 'สถานะยานพาหนะ',
          icon: 'bus-outline',
          routerLink: '/vehicle-status',
          permission: 'config-vehicle-stat'
        },
        {
          label: 'ประเภทการแจ้งเตือน',
          icon: 'pricetags-outline',
          routerLink: '/notif-types',
          permission: 'config-notif-type'
        },
        {
          label: 'ประเภทกล่อง',
          icon: 'file-tray-outline',
          routerLink: '/package-types',
          permission: 'config-package-types'
        },
        {
          label: 'ช่วงเวลา',
          icon: 'time-outline',
          routerLink: '/time-windows',
          permission: 'config-time-windows'
        },
        {
          label: 'ผู้ใช้',
          icon: 'people-outline',
          routerLink: '/users',
          permission: 'config-users'
        },
        {
          label: 'ตัวแปรระบบ',
          icon: 'construct-outline',
          routerLink: '/system-variables',
          permission: 'config-sys-vars'
        }
      ]
    },
    {
      label: 'บันทึกเหตุการณ์',
      icon: 'help-circle-outline',
      permission: 'log',
      items: [
        {
          label: 'งานยานพาหนะ',
          icon: 'newspaper-outline',
          routerLink: '/log-task',
          permission: 'log-tasks'
        },
        {
          label: 'เหตุการณ์การแจ้งเตือน',
          icon: 'notifications-circle-outline',
          routerLink: '/log-notification',
          permission: 'log-notif'
        },
        {
          label: 'เหตุการณ์ยานพาหนะ',
          icon: 'car-outline',
          routerLink: '/log-vehicle',
          permission: 'log-vehicles'
        },
        {
          label: 'เหตุการณ์ผู้ใช้',
          icon: 'people-circle-outline',
          routerLink: '/log-user',
          permission: 'log-users'
        }
      ]
    }
  ]

  constructor() { }
}
