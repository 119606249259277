import { Component, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoginService } from './api/login.service';
import { AuthenService } from './services/authen.service';
import { SystemService } from './services/system.service';
import { AboutService } from './api/about.service';
import { MenuService } from './services/menu.service';
import { RoleService } from './services/role.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild('profileMenu') profileMenu: HTMLIonPopoverElement;

  prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
  isDark: boolean;
  public themeIcon: string;
  public themeIconColor: string;

  backendVersion: string;

  isModalOpen: boolean;

  screenWidth: number;

  constructor(
    private loginService: LoginService,
    public system: SystemService,
    private about: AboutService,
    public auth: AuthenService,
    private route: ActivatedRoute,
    public menu: MenuService,
    private role: RoleService
  ) {
    this.toggleDarkTheme(this.prefersDark.matches);

    this.prefersDark.addEventListener("change", e => {
      this.isDark = !e.matches;
      this.manualToggleDarkTheme();
    });

    this.isDark = this.prefersDark.matches;

    this.isModalOpen = false;
    this.backendVersion = null;

    this.screenWidth = window.innerWidth;

    if(this.isDark) {
      this.themeIcon = 'sunny-outline';
      this.themeIconColor = 'dark';
    } else {
      this.themeIcon = 'moon-outline';
      this.themeIconColor = 'dark';
    }
  }

  manualToggleDarkTheme() {
    this.isDark = !this.isDark;

    if(this.isDark) {
      this.themeIcon = 'sunny-outline';
      this.themeIconColor = 'light';
    } else {
      this.themeIcon = 'moon-outline';
      this.themeIconColor = 'medium';
    }

    this.toggleDarkTheme(this.isDark);
  }

  private toggleDarkTheme(shouldAdd) {
    document.body.classList.toggle('dark', shouldAdd);
  }

  public checkPermission(permissionKey: string): boolean {
    let permission = this.role.permissions.find(x => x.key == permissionKey);

    if(permission) {
      return this.role.permissions.find(x => x.key == permissionKey).permission;
    } else {
      return true;
    }
  }

  private getBackendVersion(message: string): string {
    return message.substring(message.indexOf('v'), message.indexOf(')'));
  }

  ngOnInit() { }

  closeMenu() {
    this.profileMenu.dismiss();
  }

  logout() {
    this.loginService.logout();
    this.closeMenu();
  }

  openModal() {
    this.isModalOpen = true;
  }

  async didOpenModal() {
    this.backendVersion = this.getBackendVersion(await this.about.ping());
  }

  closeModal() {
    this.isModalOpen = false;
  }

  didDismissModal() {
    this.backendVersion = null;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }
}
