import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { RoleService } from './role.service';
import { LogUserService } from '../api/log-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenService {
  isLoggedIn: boolean = false;
  id: number = 0;
  userid: string = '';
  fullname: string = '';

  constructor(private router: Router, private role: RoleService, private logs: LogUserService) { }

  async isAuthened() {
    this.isLoggedIn = false;

    let auth = localStorage.getItem('authen');
    
    if(auth != null) {
      let authSession = JSON.parse(auth);

      let now = new Date();
      let loggedTime = new Date(authSession.timestamp);

      if((now.getTime() - loggedTime.getTime()) < 86400000) {
        this.isLoggedIn = true;

        this.id = authSession.id;
        this.fullname = authSession.name;
        this.userid = authSession.userid;

        let roleparams = JSON.parse(authSession.roleparam);
        this.role.permissions.map(x => x.permission = roleparams[x.key] ? true : false);
      } else {
        let log = {
          user_id: authSession.id,
          incident: 'user session expired',
          logdetail: '{}'
        }
    
        this.logs.createUserLogs(log);

        localStorage.removeItem('authen');
        this.router.navigate(['/login']);
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  logoutCompleted() {
    this.isLoggedIn = false;
    this.id = 0;
    this.userid = '';
    this.fullname = '';
  }
}
