import { Injectable } from '@angular/core';

import { UsersService } from './users.service';
import { AuthenService } from '../services/authen.service';
import { LogUserService } from './log-user.service';

import { userAPIResponse } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private logErrorMessage: userAPIResponse = {
    status: 0,
    result: [],
    message: 'ระบบขัดข้องไม่สามารถดำเนินการได้'
  }

  constructor(
    private userservice: UsersService,
    private auth: AuthenService,
    private logs: LogUserService
  ) { }

  async login(formValue): Promise<userAPIResponse> {
    let res: userAPIResponse;
    try {
      res = await this.userservice.authenUser(formValue.userid, formValue.passcode);

      if(res.status) {
        let user = await this.userservice.getUser(res.result.pop().id);

        let logMessage: string;
        switch(res.status) {
          case 0:
            logMessage = 'failed';
            break;
          case 1:
            logMessage = 'success';
            break;
        }

        let log = {
          user_id: user.id,
          incident: 'user login',
          logdetail: '{}'
        }
        await this.logs.createUserLogs(log);

        localStorage.setItem('authen', JSON.stringify({
          id: user.id,
          userid: user.userid,
          name: user.firstname + " " + user.lastname, roleparam: user.roleparam,
          timestamp: new Date().toString()
        }));
      }
    } catch(err) {
      res = this.logErrorMessage;
      console.log('LocationAPI', err);
    }

    return res;
  }

  public logout() {
    let log = {
      user_id: this.auth.id,
      incident: 'user logout',
      logdetail: '{}'
    }

    this.logs.createUserLogs(log);

    localStorage.removeItem('authen');

    this.auth.logoutCompleted();
    this.auth.isAuthened();
  }
}
