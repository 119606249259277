import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  public domain: string;

  constructor(@Inject(DOCUMENT) private document: any) {
    // this.domain = 'http://' + this.document.location.hostname + '/ba_tmsx';
    this.domain = 'https://services.origin55.com/ba_tmsx';

    // this.domain = 'https://' + this.document.location.hostname + ':52525';
  }
}